import { merge } from "lodash";

export const transformOptions = (options) => {
  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: () => "",
      },
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 10,
          },
          boxWidth: 10,
          color: "#4F4F4F",
        },
      },
    },
    interaction: {
      intersect: false,
    },
    radius: 0,
  };

  return merge(defaultOptions, options);
};

export const transformPlugins = (plugins) => {
  // We can set some default plugins that be suitable for amdb
  return plugins || [];
};

export const transformData = (data) => {
  // We can customize data that be suitable for amdb
  return data;
};

export const afterBuildTicks = (scale) => {
  const { ticks, chart } = scale;
  const maxTicksLimit = chart?.options?.scales?.x?.ticks?.maxTicksLimit || 1;

  if (ticks.length <= maxTicksLimit) return;

  const calcTicksLimit = Math.max(maxTicksLimit - 1, 1); // spaces between ticks
  const step = Math.floor(ticks.length / calcTicksLimit);

  let count = 0;
  scale.ticks = ticks.filter((tick, index) => {
    const isMatch = index % step === 0;
    const isLastIndex = ticks.length - 1 === index;

    if (isMatch) count++;

    return (isMatch && count < maxTicksLimit) || isLastIndex;
  });
};
