<template>
  <div>
    <RDECatalogTable
      showExpand
      :headers="headers"
      :items="extensionPackages"
      item-key="name"
      singleExpand
    >
      <template #toolbar>
        <div class="flex justify-end mb-2">
          <Button
            fill
            color="primary"
            class="normal-case"
            size="md"
            @click="openPopup"
          >
            <v-icon class="mr-1">mdi-plus</v-icon>
            Add
          </Button>
        </div>
      </template>
      <template #expanded-item="{ item }">
        <RDEChildTable :items="item.extensions" :headers="extensionHeaders">
          <template v-slot:[`item.created`]="{ item }">
            {{ item.created | formatDate }}
          </template>
        </RDEChildTable>
      </template>
      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | formatDate }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="flex justify-center gap-2">
          <v-icon
            class="cursor-pointer"
            @click="() => editExtensionPackage(item)"
          >
            mdi-pencil
          </v-icon>
        </div>
      </template>
    </RDECatalogTable>
    <Dialog
      :value="isOpenPopup"
      persistent
      :title="defaultEditValue ? 'Edit Package' : 'Create Package'"
      max-width="800"
      min-height="700"
      @input="(value) => (!value ? closePopup() : (isOpenPopup = true))"
    >
      <ExtensionPackagePopup
        v-if="isOpenPopup"
        :closePopup="closePopup"
        :reloadData="fetchExtensionPackage"
        :defaultEditValue="defaultEditValue"
      />
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/components/compositions/Dialog/Dialog.vue";
import RDECatalogTable from "../../components/RDECatalogTable.vue";
import RDEChildTable from "../../components/RDEChildTable.vue";
import ExtensionPackagePopup from "./ExtensionPackagePopup.vue";
import moment from "moment";
import CommonUIControl from "@/helper/CommonUIControl";
import { requestExtensionPackages } from "@/service/apis/workspaceApis";
import { sumBy } from "lodash";

export default {
  components: { RDECatalogTable, RDEChildTable, Dialog, ExtensionPackagePopup },
  data: () => ({
    headers: [
      {
        text: "Name",
        value: "name",
        width: "15%",
        minWidth: 150,
      },
      {
        text: "Display Name",
        value: "displayName",
        width: "15%",
        minWidth: 150,
      },
      {
        text: "Type",
        value: "type",
        width: "15%",
        minWidth: 150,
        sortable: false,
      },
      {
        text: "Description",
        value: "description",
        width: "20%",
        minWidth: 150,
        sortable: false,
      },
      {
        text: "Size",
        value: "size",
        width: "15%",
        minWidth: 150,
        sortable: false,
      },
      {
        text: "Created",
        value: "created",
        width: "15%",
        minWidth: 150,
        sortable: false,
      },
      {
        text: "Action",
        value: "action",
        width: "5%",
        minWidth: 100,
        sortable: false,
      },
    ],
    extensionHeaders: [
      { text: "", value: "emptyCol", width: 100, sortable: false },
      { text: "Name", value: "name", width: "20%" },
      { text: "Organization", value: "organization", width: "20%" },
      { text: "Version", value: "version", width: "20%" },
      {
        text: "Size",
        value: "size",
        width: "15%",
      },
      { text: "Created", value: "created", width: "20%" },
    ],
    extensionPackages: [],
    isOpenPopup: false,
    defaultEditValue: null,
  }),
  methods: {
    openPopup: function () {
      this.isOpenPopup = true;
      this.defaultEditValue = null;
    },

    editExtensionPackage(item) {
      this.isOpenPopup = true;
      this.defaultEditValue = item;
    },
    closePopup() {
      this.isOpenPopup = false;
      this.defaultEditValue = null;
    },
    async fetchExtensionPackage(showLoading) {
      if (showLoading) CommonUIControl.ShowUIProgress();
      requestExtensionPackages()
        .then((res) => {
          this.extensionPackages = (res?.data?.data || []).map((ep) => {
            ep.size =
              sumBy(ep.extensions || [], (e) =>
                Number(e.size?.split(" ")[0]),
              ).toFixed(1) + " Mib";

            return ep;
          });
        })
        .catch(() => {
          CommonUIControl.ShowErrorToast(
            "Fetch extension packages failed",
            5000,
          );
        })
        .finally(() => {
          if (showLoading) CommonUIControl.HideUIProgress();
        });
    },
  },
  filters: {
    formatDate(value) {
      return value ? moment(new Date(value)).format("YYYY/MM/DD HH:mm:ss") : "";
    },
  },
  mounted() {
    this.fetchExtensionPackage(true);
  },
};
</script>

<style lang="scss"></style>
