
<RDECatalogTable :headers="headers" :items="images">
  <template #filter-extra>
    <div class="font-normal text-xs">Show Selected Type</div>
    <v-btn-toggle
      v-model="typeFilters"
      group
      multiple
      active-class="btn-toggle-on"
      light
      color="white"
    >
      <v-btn
        v-for="type in types"
        :key="type"
        :value="type"
        :title="type"
        outlined
        class="btn-toggle-off"
      >
        <SvgIcon
          :iconName="type === 'webssh' ? 'ssh' : type"
          :size="type === 'webssh' ? 'md' : 'sm'"
          :color="typeFilters.includes(type) ? getIconColor(type) : '#434343'"
        />
      </v-btn>
    </v-btn-toggle>
  </template>

  <template v-slot:[`item.icon`]="{ item }">
    <v-icon v-if="item.type === 'unknown'"> mdi-file-question </v-icon>
    <SvgIcon
      v-else
      :iconName="item.type === 'webssh' ? 'ssh' : item.type"
      :size="item.type === 'webssh' ? 'md' : 'sm'"
      :color="getIconColor(item.type)"
    />
  </template>
  <template v-slot:[`item.created`]="{ item }">
    {{ item.created | formatDate }}
  </template>
</RDECatalogTable>
