<template>
  <!-- <div v-if="isShow"> -->
  <LineChartJS
    v-if="isShow"
    :ref="chartKey"
    :data="finalData"
    :options="finalOptions"
  />
  <NoContent v-else-if="!hideNoData" :message="noDataMessage" />
  <!-- </div> -->
</template>

<script lang="ts">
import { Line as LineChartJS } from "vue-chartjs";

import { isEmpty } from "lodash";
import { transformOptions, transformPlugins, transformData } from "./handler";
import NoContent from "@/components/notices/NoContent.vue";

export default {
  name: "LineChart",
  props: {
    chartKey: { type: String },
    data: { type: Object },
    options: { type: Object },
    plugins: { type: Array },
    hideNoData: { type: Boolean, default: false },
    noDataMessage: { type: String },
  },
  components: {
    LineChartJS,
    NoContent,
  },
  data() {
    return {};
  },
  computed: {
    isShow: function () {
      return !isEmpty(this.data?.datasets);
    },
    finalOptions() {
      // In next time, we can customize something before push into line chart component
      return transformOptions(this.options);
    },
    finalData() {
      return transformData(this.data);
    },
    finalPlugins() {
      return transformPlugins(this.plugins || []);
    },
  },
  watch: {
    isShow: {
      immediate: true,
      handler() {
        if (this.isShow && this.chartKey) {
          setTimeout(() => {
            window.charts = {
              ...(window.charts || {}),
              [this.chartKey]: {
                getAllData: () => {
                  return {
                    data: this.finalData,
                    plugins: this.finalPlugins,
                    options: this.finalOptions,
                  };
                },
              },
            };
          }, 500);
        }
      },
    },
  },
  mounted() {},
  updated() {},
};
</script>
