
<div>
  <RDECatalogTable
    showExpand
    :headers="headers"
    :items="extensions"
    item-key="name"
    singleExpand
    ignoreTdSpanDetail
    :showExpandByRow="showExpandByRow"
  >
    <!-- <template #toolbar>
      <div class="flex justify-end mb-2">
        <Button
          fill
          color="primary"
          class="normal-case"
          size="md"
          @click="openPopup"
        >
          <v-icon class="mr-1">mdi-plus</v-icon>
          Add
        </Button>
      </div>
    </template> -->
    <template #expanded-item="{ item }">
      <tr
        v-for="childExtension in item.versions"
        :key="childExtension.version"
      >
        <td></td>
        <td></td>
        <td></td>
        <td>
          <span class="line-clamp-2 break-all">
            {{ childExtension.version }}
          </span>
        </td>
        <td>
          <span class="line-clamp-2 break-all">
            {{ childExtension.size }}
          </span>
        </td>
        <td>
          <span class="line-clamp-2 break-all">
            {{ childExtension.created | formatDate }}
          </span>
        </td>
      </tr>
    </template>
    <template v-slot:[`item.created`]="{ item }">
      {{ item.created | formatDate }}
    </template>
  </RDECatalogTable>
  <Dialog
    :value="isOpenPopup"
    persistent
    title="Add Extension"
    max-width="800"
    min-height="700"
    @input="() => (isOpenPopup = true)"
  >
    <ExtensionPopup
      v-if="isOpenPopup"
      :closePopup="closePopup"
      :reloadData="fetchExtensions"
    />
  </Dialog>
</div>
