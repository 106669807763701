<template>
  <div class="atoms__file-uploader relative">
    <div
      role="button"
      class="relative border rounded-lg h-32 gap-y-2 overflow-hidden transition-colors"
    >
      <!-- 파일이 있을 경우 -->
      <template v-if="files?.length > 0">
        <div
          class="!absolute top-2 right-2 z-1 rounded-full"
          :style="{
            backgroundColor: 'rgba(255,255,255,0.2)',
          }"
        >
          <IconButton
            iconName="mdi-close"
            shape="circle"
            tooltip="deselect"
            :outlined="false"
            @click.stop="fileClear"
          />
        </div>

        <!-- 단일 파일 -->
        <div
          v-if="files.length === 1"
          class="flex flex-col justify-center items-center h-inherit cursor-default"
        >
          <!-- 이미지 -->
          <template v-if="files[0]?.image">
            <img
              class="h-32 w-full object-cover"
              alt="upload image"
              :src="files[0].image"
            />
          </template>
          <!-- 이미지 외 파일 -->
          <template v-else>
            <v-icon>mdi-file-outline</v-icon>
            <div class="w-full text-gray-500 text-sm truncate px-2 text-center">
              {{ files[0].name }}
            </div>
          </template>
          <Tooltip>{{ files[0].name }}</Tooltip>
        </div>

        <!-- 다중 파일 -->
        <div
          v-else-if="files.length > 1"
          class="h-32 w-full overflow-y-hidden overflow-x-auto py-2 cursor-default"
        >
          <div
            class="flex gap-2 pl-2"
            :style="{ width: `${(112 + 8) * files.length + 8}px` }"
          >
            <div
              v-for="(file, index) of files"
              :key="`file-upload-file-${index}`"
              class="!h-[112px] w-28 flex flex-col justify-center items-center bg-gray-50 border rounded-lg overflow-hidden"
              :class="[`${file.image ? '' : 'p-2'}`]"
            >
              <!-- 이미지 -->
              <template v-if="file.image">
                <img
                  class="h-inherit w-inherit object-cover"
                  alt="upload image"
                  :src="file.image"
                />
              </template>
              <!-- 이미지외 파일 -->
              <template v-else>
                <v-icon>mdi-file-outline</v-icon>
                <div
                  class="w-inherit text-gray-500 text-xs truncate mt-2 px-2 text-center"
                >
                  {{ file.name }}
                </div>
              </template>
              <Tooltip>{{ file.name }}</Tooltip>
            </div>
          </div>
        </div>
      </template>
      <!-- 파일이 없을 경우 -->
      <template v-else>
        <div
          class="w-full h-inherit cursor-pointer flex flex-col gap-y-1 justify-center items-center"
          @click="activeFilleSelector"
        >
          <v-icon x-large>mdi-upload-network</v-icon>
          <div class="text-base font-normal" style="color: #131316">
            Click or drag file to this area to upload
          </div>
          <div class="font-normal text-sm" style="color: #9f9fa4">
            {{ placeholder }}
          </div>
        </div>
      </template>
    </div>

    <!-- 로딩 표시 -->
    <div
      v-if="loading"
      class="h-full w-full bg-[rgba(0,0,0,0.3)] absolute top-0 left-0 cursor-default flex justify-center items-center"
      :style="{
        backdropFilter: 'blur(4px)',
      }"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <v-file-input
      ref="fileInput"
      :multiple="multiple"
      :value="value"
      :accept="accept"
      :rules="[rules.required]"
      v-bind="$attrs"
      v-on="$listeners"
      @input="(v) => $emit('input', v)"
      @change="handleFileChange"
    />
  </div>
</template>

<script>
import CommonUIControl from "@/helper/CommonUIControl";
import { RulesMixin } from "@/mixins/RulesMixin";

export default {
  /** ===== props ===== */
  props: {
    /** 값 */
    value: {
      type: Object,
    },
    /** 파일 제한 */
    accept: {
      type: String,
    },
    /** 파일 제한 */
    placeholder: {
      type: String,
      default: "Please select a file",
    },
    /** 파일 크기 제한. */
    size: {
      size: Number,
      default: 10000,
    },
    /** true일 경우 다중으로 파일을 업로드 */
    multiple: {
      type: Boolean,
    },
    /** true일 경우 로딩 화면 표시 */
    loading: {
      type: Boolean,
    },
  },
  /** ===== mixins ===== */
  mixins: [RulesMixin],
  /** ===== emits ===== */
  emits: [],
  /** ===== data ===== */
  data: () => ({
    images: [],
    readers: [],
    files: [],
  }),
  /** ===== computed ===== */
  computed: {},
  /** ===== watch ===== */
  watch: {},
  /** ===== methods ===== */
  methods: {
    /** 값 변경 */
    handleFileChange(files) {
      if (!files) {
        this.initialize();
        return;
      }

      const newFiles = Array.isArray(files) ? files : [files];

      // # 유효성 검사
      let filteredFiles = newFiles?.filter(
        (file) =>
          !!file &&
          (this.accept ? fileTypeChecking(file, this.accept) : true) &&
          (this.size ? file.size <= this.size * 1024 : true),
      );

      // # 파일 타입 검사
      const invalidFiles = newFiles.filter((file) =>
        this.accept ? !fileTypeChecking(file, this.accept) : false,
      );
      if (invalidFiles.length > 0) {
        const fileNames = invalidFiles.map((a) => a.name).join("");
        const message = `Invalid file type. ${fileNames}`;
        CommonUIControl.ShowErrorToast(message, 3000);
        filteredFiles = [];
      }

      // # 파일 사이즈 검사
      const overSizeFiles = newFiles.filter((file) =>
        this.size ? file.size > this.size * 1024 : false,
      );
      if (overSizeFiles.length > 0) {
        const fileNames = overSizeFiles.map((a) => a.name).join("");
        const limitSize = new Intl.NumberFormat("ko").format(this.size);
        const message = `The file size exceeded ${limitSize}KB. ${fileNames}`;

        CommonUIControl.ShowErrorToast(message, 3000);
        filteredFiles = [];
      }

      // # 파일 업데이트
      filteredFiles.forEach((file, index) => {
        const reader = new FileReader();

        // # 읽은 정보 담기
        this.readers.push(reader);

        // # 이미지일 경우
        if (file.type.startsWith("image/") === true) {
          // # 이미지를 base64로
          reader.readAsDataURL(file);
          // # 파일 읽기 콜백 정의
          reader.onload = ({ target: { result } }) => {
            if (!result) {
              return;
            }
            this.files = this.files.map((a, i) =>
              index === i ? ((a.image = result), a) : a,
            );
          };
        }
      });

      this.files = filteredFiles;

      // # 유효성 검사를 통과 못하거나 해서 파일이 없을 경우
      if (filteredFiles.length === 0) {
        this.$refs.fileInput.$refs.input.value = null;
        setTimeout(() => {
          this.fileClear();
        }, 0);
      }

      this.$emit("chagne", this.files);
    },
    /** 파일 선택 활성화 */
    activeFilleSelector() {
      // console.log("> click");
      this.$refs.fileInput.$el
        .querySelector(".v-icon--link.mdi-paperclip")
        .dispatchEvent(new Event("click"));
    },
    /** 파일 제거 */
    fileClear() {
      this.$refs.fileInput.$el
        .querySelector(".v-icon--link.mdi-close")
        ?.dispatchEvent(new Event("click"));
      this.initialize();
    },
    /** 이미지를 base64로 변환후 처리 */
    loadImageConversion(e) {
      const { result } = e.target;

      if (!result) {
        return;
      }
      this.images.push(result);
    },
    /** 초기화 */
    initialize() {
      this.readers?.forEach((r) => {
        if (r?.readyState === 1) {
          r.abort();
        }
      });
      this.images = [];
      this.readers = [];
      this.files = [];
    },
  },
  /** ===== mounted ===== */
  mounted() {},
  /** ===== beforeDestroy ===== */
  beforeDestroy() {
    this.initialize();
  },
};

/** 파일 타입의 유효성을 검사한다
 * @param {File} file - 파일 포맷
 * @param {string} accept - 파일제한 문자열
 */
const fileTypeChecking = (file, accept) => {
  const types = accept.split(",").map((a) => a.trim());
  const result = types.some((type) => {
    const regExp = new RegExp(type);
    return regExp.test(file.type);
  });
  // console.log(file, types, result);
  return result;
};
</script>

<style lang="scss">
.atoms__file-uploader {
  .v-file-input {
    padding-top: 0px;
    margin-top: 0px;
  }
  .v-file-input .v-input__prepend-outer,
  .v-file-input .v-input__slot {
    display: none;
  }
  .v-text-field__details {
    margin-top: 2px;
  }
}
</style>
