
<div class="atoms__file-uploader relative">
  <div
    role="button"
    class="relative border rounded-lg h-32 gap-y-2 overflow-hidden transition-colors"
  >
    <!-- 파일이 있을 경우 -->
    <template v-if="files?.length > 0">
      <div
        class="!absolute top-2 right-2 z-1 rounded-full"
        :style="{
          backgroundColor: 'rgba(255,255,255,0.2)',
        }"
      >
        <IconButton
          iconName="mdi-close"
          shape="circle"
          tooltip="deselect"
          :outlined="false"
          @click.stop="fileClear"
        />
      </div>

      <!-- 단일 파일 -->
      <div
        v-if="files.length === 1"
        class="flex flex-col justify-center items-center h-inherit cursor-default"
      >
        <!-- 이미지 -->
        <template v-if="files[0]?.image">
          <img
            class="h-32 w-full object-cover"
            alt="upload image"
            :src="files[0].image"
          />
        </template>
        <!-- 이미지 외 파일 -->
        <template v-else>
          <v-icon>mdi-file-outline</v-icon>
          <div class="w-full text-gray-500 text-sm truncate px-2 text-center">
            {{ files[0].name }}
          </div>
        </template>
        <Tooltip>{{ files[0].name }}</Tooltip>
      </div>

      <!-- 다중 파일 -->
      <div
        v-else-if="files.length > 1"
        class="h-32 w-full overflow-y-hidden overflow-x-auto py-2 cursor-default"
      >
        <div
          class="flex gap-2 pl-2"
          :style="{ width: `${(112 + 8) * files.length + 8}px` }"
        >
          <div
            v-for="(file, index) of files"
            :key="`file-upload-file-${index}`"
            class="!h-[112px] w-28 flex flex-col justify-center items-center bg-gray-50 border rounded-lg overflow-hidden"
            :class="[`${file.image ? '' : 'p-2'}`]"
          >
            <!-- 이미지 -->
            <template v-if="file.image">
              <img
                class="h-inherit w-inherit object-cover"
                alt="upload image"
                :src="file.image"
              />
            </template>
            <!-- 이미지외 파일 -->
            <template v-else>
              <v-icon>mdi-file-outline</v-icon>
              <div
                class="w-inherit text-gray-500 text-xs truncate mt-2 px-2 text-center"
              >
                {{ file.name }}
              </div>
            </template>
            <Tooltip>{{ file.name }}</Tooltip>
          </div>
        </div>
      </div>
    </template>
    <!-- 파일이 없을 경우 -->
    <template v-else>
      <div
        class="w-full h-inherit cursor-pointer flex flex-col gap-y-1 justify-center items-center"
        @click="activeFilleSelector"
      >
        <v-icon x-large>mdi-upload-network</v-icon>
        <div class="text-base font-normal" style="color: #131316">
          Click or drag file to this area to upload
        </div>
        <div class="font-normal text-sm" style="color: #9f9fa4">
          {{ placeholder }}
        </div>
      </div>
    </template>
  </div>

  <!-- 로딩 표시 -->
  <div
    v-if="loading"
    class="h-full w-full bg-[rgba(0,0,0,0.3)] absolute top-0 left-0 cursor-default flex justify-center items-center"
    :style="{
      backdropFilter: 'blur(4px)',
    }"
  >
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>

  <v-file-input
    ref="fileInput"
    :multiple="multiple"
    :value="value"
    :accept="accept"
    :rules="[rules.required]"
    v-bind="$attrs"
    v-on="$listeners"
    @input="(v) => $emit('input', v)"
    @change="handleFileChange"
  />
</div>
