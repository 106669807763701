
<div class="compositions__tabs" :class="[usePadding ? 'use-padding' : '']">
  <div :class="tabsClass">
    <v-tabs
      v-model="index"
      v-bind="$attrs"
      v-on="$listeners"
      @change="handleInputChange"
    >
      <template #default>
        <slot name="tabs" />
      </template>
    </v-tabs>
  </div>

  <hr />

  <div class="overflow-hidden" :class="itemsClass">
    <v-tabs-items v-model="index">
      <slot name="items" />
    </v-tabs-items>
  </div>
</div>
