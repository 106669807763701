<template>
  <v-tab-item class="tabs__tab-item" v-bind="$attrs" v-on="$listeners">
    <template #default>
      <slot></slot>
    </template>
  </v-tab-item>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
