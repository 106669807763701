
<!-- <div v-if="isShow"> -->
<LineChartJS
  v-if="isShow"
  :ref="chartKey"
  :data="finalData"
  :options="finalOptions"
/>
<NoContent v-else-if="!hideNoData" :message="noDataMessage" />
<!-- </div> -->
