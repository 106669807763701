<template>
  <v-autocomplete
    ref="multiSelect"
    v-model="internalValue"
    :label="label"
    :items="localItems"
    small-chips
    :placeholder="placeholder"
    :class="extClass"
    :clearable="true"
    :deletable-chips="true"
    multiple
    outlined
    dense
    @change="onClearCheckBox"
  >
    <template v-slot:selection="{ index }">
      <v-chip v-if="selectedChild" :key="index" small class="my-chip">
        <div class="text-center flex flex-col">
          <span class="name">
            {{ selectedChild.imageName }}.{{ selectedChild?.imageNameDetail }}:
            {{ selectedChild?.version || "" }}</span
          >
        </div>
      </v-chip>
    </template>

    <template v-slot:item="{ item, index }">
      <v-list-item :key="index" class="image-list" @click.stop>
        <v-list-item-content>
          <v-radio-group
            v-model="selectedValue"
            hide-details
            class="rdo-group w-full"
          >
            <div class="w-full flex flex-row gap-2 parent-item">
              <!-- <div>
                <v-radio
                  class="pt-5"
                  :value="item"
                  @change="toggleParent(item)"
                ></v-radio>
              </div> -->
              <v-list-group v-model="item.expanded" class="w-full" @click.stop>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div class="font-bold">
                        {{ item.name }}
                      </div>
                      <div class="extra-info">
                        Type: {{ item.type }} <br />
                        Description:
                        {{
                          $helper.truncatedText(
                            item?.description?.toString() || "",
                            30,
                          )
                        }}
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="(child, childIndex) in item.images"
                  :key="childIndex"
                  @click.stop
                >
                  <v-list-item-content
                    class="w-full flex flex-row gap-2 child-item"
                  >
                    <div class="w-fit flex-none">
                      <v-radio
                        :value="child"
                        @change="toggleChild(item, child)"
                      ></v-radio>
                    </div>

                    <div class="child-body w-fit flex-none py-2">
                      <div class="font-bold py-1">
                        {{ child.imageNameDetail }}
                      </div>
                      <div class="extra-info">
                        Image Name: {{ child.imageName }} <br />
                        version: {{ child.version }}
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </div>
          </v-radio-group>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  props: {
    defaultValue: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
    extClass: {
      type: String,
      default: "",
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Select",
    },
    xModel: {
      type: Object,
      default: () => ({}),
    },
    setValue: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      internalValue: [],
      localItems: cloneDeep(this.items),
      init: false,
      selectedValue: null,
      selectedParent: null,
      selectedChild: null,
    };
  },
  watch: {
    defaultValue: {
      immediate: true,
      handler() {
        this.initData();
      },
    },
    items: {
      immediate: true,
      handler() {
        this.initData();
      },
    },
    internalValue: {
      immediate: true,
      handler() {
        if (this.selectedParent && this.selectedChild) {
          this.setValue({
            image: `${this.selectedChild.imageName}.${this.selectedChild.imageNameDetail}:${this.selectedChild.version}`,
            imageId: this.selectedChild.id,
            imageGroupId: this.selectedParent.id,
          });
        } else {
          this.setValue({
            image: "",
            imageId: "",
            imageGroupId: "",
          });
        }
      },
    },
  },
  methods: {
    toggleParent(item) {
      this.localItems.forEach((parent) => {
        parent.selected = false;
        if (parent.images) {
          parent.images.forEach((child) => {
            child.selected = false;
          });
        }
      });
      const parentItem = this.localItems.find(
        (parent) => parent.id === item.id,
      );

      if (parentItem) {
        this.selectedParent = parentItem;
        parentItem.selected = true;
        this.selectedChild = null;
      }
      this.internalValue = this.localItems.filter((item) => item.selected);
    },
    toggleChild(parent, child) {
      this.selectedParent = parent;
      this.localItems.forEach((parent) => {
        parent.selected = false;
        if (parent.images) {
          parent.images.forEach((child) => {
            child.selected = false;
          });
        }
      });
      const parentItem = this.localItems.find((p) => p.id === parent.id);

      if (parentItem) {
        parentItem.selected = true;
        const childItem = parentItem.images.find((c) => c.id === child.id);

        if (childItem) {
          childItem.selected = true;
          this.selectedChild = childItem;
        }
      }
      this.internalValue = this.localItems.filter((item) => item.selected);
      this.$refs.multiSelect.blur();
    },
    initData() {
      if (!this.init && this.defaultValue && this.items.length > 0) {
        let imageId = this.defaultValue.imageId;
        let imageGroupId = this.defaultValue.imageGroupId;

        if (!imageId || !imageGroupId) return;

        imageId = Number(imageId);
        imageGroupId = Number(imageGroupId);

        this.localItems.forEach((parent) => {
          parent.selected = false;
          if (parent.images) {
            parent.images.forEach((child) => {
              child.selected = false;
            });
          }
        });

        //Find the parent item
        const parentItem = this.localItems.find(
          (parent) => parent.id === imageGroupId,
        );

        if (parentItem) {
          parentItem.selected = true;
          this.selectedParent = parentItem;
          if (imageId) {
            // Find the child item
            const childItem = parentItem.images.find(
              (child) => child.id === imageId,
            );

            if (childItem) {
              childItem.selected = true;
              this.selectedChild = childItem;
              this.selectedValue = childItem;
            }
          } else {
            this.selectedValue = parentItem;
          }
        }
        this.internalValue = this.localItems.filter((item) => item.selected);
        this.init = true;
      }
    },
    onClearCheckBox() {
      if (!this.internalValue || this.internalValue.length == 0) {
        this.localItems.forEach((parent) => {
          parent.selected = false;
          if (parent.images) {
            parent.images.forEach((child) => {
              child.selected = false;
            });
          }
        });
        this.selectedValue = null;
        this.selectedParent = null;
        this.selectedChild = null;
        this.$emit("input", "");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox-parent {
  &.v-input--selection-controls {
    margin-top: 0;
    padding-bottom: 0;
  }
  &.v-messages {
    display: none !important;
  }
}
.checkbox-parent .v-messages,
.checkbox-child .v-messages {
  display: none;
}
.extra-info {
  color: #646569;
  line-height: 18px;
}

.child-item,
.parent-item {
  border-bottom: 1px solid #cdced3;
}
.checkbox-child {
  margin-top: 0;
  display: inline-block !important;
}
.child-body {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.004em;
  text-align: left;
}
.name {
  line-height: 14px;
}
.version {
  font-size: 8px;
  color: #646569;
  line-height: 12px;
}
.my-chip {
  background-color: #321fdb !important;
  color: #fff !important;
}
.rdo-group {
  margin-top: 0 !important;
}
</style>
