<template>
  <Form
    v-if="defaultValues"
    ref="imageGroupform"
    class="w-full"
    :defaultValues="defaultValues"
    v-bind="$attrs"
    :before="handleSubmitBefore"
    v-on="$listeners"
  >
    <template #default="{ values }">
      <v-autocomplete
        v-model="values.type"
        label="Select Type"
        itemText="label"
        itemValue="value"
        :items="typeOptions"
        class="app-g-autocomplete-v2 mb-2"
        clearable
        outlined
        dense
        :rules="[rules.required]"
        :disabled="!!defaultEditValue"
        @change="changeType"
      />
      <div class="grid grid-cols-2 gap-x-2">
        <Input
          v-model="values.name"
          label="*Enter group name"
          :rules="[rules.required]"
          appendClass="app-g-input-v2"
          :disabled="!!defaultEditValue"
        />
        <Input
          v-model="values.description"
          label="Enter description of this group"
          appendClass="app-g-input-v2 "
        />
      </div>
      <div
        class="grid grid-cols-2 gap-x-2 pt-2 pb-3"
        style="color: #131316; font-weight: 500; font-size: 14px"
      >
        Selected Images
      </div>

      <RDECatalogTable
        v-if="!!values.type"
        :value="selectedImages"
        show-select
        :headers="headers"
        :items="sortedImages"
        item-key="customId"
        item-value="customId"
        :items-per-page="5"
        disable-sort
        @input="selectItems"
      >
      </RDECatalogTable>

      <div class="flex justify-end gap-x-2 mt-4">
        <Button :size="'md'" @click="closePopup">Cancel</Button>
        <Button :type="'submit'" color="primary" :size="'md'" text fill>
          Save
        </Button>
      </div>
    </template>
  </Form>
</template>

<script>
import Input from "@/components/atoms/Input/Input.vue";
import CommonUIControl from "@/helper/CommonUIControl";
import { RulesMixin } from "@/mixins/RulesMixin";
import RDECatalogTable from "../../components/RDECatalogTable.vue";
import {
  requestImageGroups,
  requestImages,
} from "@/service/apis/workspaceApis";

export default {
  props: ["closePopup", "reloadData", "defaultEditValue"],
  components: { Input, RDECatalogTable },
  mixins: [RulesMixin],
  data: () => ({
    headers: [
      {
        text: "Type",
        value: "type",
        width: "25%",
      },
      { text: "Image Name Detail", value: "imageNameDetail", width: "40%" },
      { text: "Version", value: "version", width: "30%" },
    ],
    imageOptions: [],
    typeOptions: [
      { label: "VS Code", value: "vscode" },
      { label: "Web SSH", value: "webssh" },
      { label: "Jupyter", value: "jupyter" },
    ],
    defaultValues: null,
    selectedImages: [],
    images: [],
  }),
  computed: {
    sortedImages() {
      return this.selectedImages.concat(
        this.imageOptions.filter(
          (i) =>
            !this.selectedImages.length ||
            this.selectedImages.every((s) => s.customId !== i.customId),
        ),
      );
    },
  },
  watch: {
    defaultEditValue: {
      async handler() {
        // get images
        CommonUIControl.ShowUIProgress();
        await requestImages()
          .then((res) => {
            this.images = res?.data || [];
          })
          .catch(() => {
            CommonUIControl.ShowErrorToast("Fetch Image faield");
          })
          .finally(() => {
            CommonUIControl.HideUIProgress();
          });

        if (this.defaultEditValue) {
          const currentImages = (this.defaultEditValue.images || []).map(
            (image) => ({
              ...image,
              type: image.type || this.defaultEditValue.type,
              customId: `${image.imageNameDetail}|${image.version}`,
            }),
          );

          this.defaultValues = {
            ...this.defaultEditValue,
            images: currentImages,
          };
          this.selectedImages = currentImages;
          // call api get images options
          this.imageOptions = this.images
            ?.filter((image) => image.type === this.defaultEditValue.type)
            ?.map((image) => ({
              ...image,
              customId: `${image.imageNameDetail}|${image.version}`,
            }));
        } else {
          this.defaultValues = { images: [] };
        }
      },
      immediate: true,
    },
  },
  methods: {
    selectItems(selectedItems) {
      this.$nextTick().then(() => {
        this.selectedImages = selectedItems;
      });
    },
    changeType(value) {
      if (!value) {
        this.imageOptions = [];
      } else {
        this.imageOptions = this.images
          ?.filter((image) => image.type === value)
          .map((image) => ({
            ...image,
            customId: `${image.imageNameDetail}|${image.version}`,
          }));
      }
      this.$refs.imageGroupform.setValue("images", []);
      this.selectedImages = [];
    },
    async handleSubmitBefore(values) {
      if (!this.selectedImages?.length) {
        CommonUIControl.ShowWarningToast(
          "You must select at least one image.",
          5000,
        );
        return false;
      }

      CommonUIControl.ShowUIProgress();

      const payload = {
        ...values,
        name: values.name?.trim() || "",
        description: values.description?.trim() || "",
        images: this.selectedImages.map((image) => {
          const imageData = {
            type: image.type,
            imageName: image.imageName,
            imageNameDetail: image.imageNameDetail,
            description: image.description,
            version: image.version,
            created: image.created,
          };

          if (values.id) {
            imageData.groupId = values.id;
          }
          return imageData;
        }),
      };
      CommonUIControl.ShowUIProgress();

      await requestImageGroups({
        method: this.defaultEditValue ? "put" : "post",
        data: payload,
        path: this.defaultEditValue ? `/${this.defaultEditValue.id}` : "",
      })
        .then(() => {
          CommonUIControl.ShowSuccessToast(
            `Image Group ${
              this.defaultEditValue ? "updated" : "created"
            } successfully.`,
            5000,
          );
        })
        .catch((e) => {
          CommonUIControl.ShowErrorToast(e.response.data.message, 5000);
        })
        .finally(() => {
          CommonUIControl.HideUIProgress();
        });
      setTimeout(() => {
        this.closePopup();
        this.reloadData();
      }, 100);

      return false;
    },
  },
};
</script>

<style lang="scss"></style>
