import CommonUIControl from "@/helper/CommonUIControl";
import { exportFile } from "@/helper/papaparseHelper";
import { cloneDeep, get, isEmpty, omit } from "lodash";
import moment from "moment";
import {
  COLORS,
  CONVERT_TIME_UNIT,
  DATETIME_FORMAT,
  FIELD_EXPORT_CONFIG,
  mapRequestCICDFields,
} from "./constant";

export const transformParamsRequest = (filterState) => {
  const finalResult = {};
  Object.keys(mapRequestCICDFields).forEach((key) => {
    const value = get(filterState, key);
    if (value || typeof value === "boolean") {
      if (["fromDate", "toDate"].includes(mapRequestCICDFields[key])) {
        // Date param should be ISO STRING DATE
        finalResult[mapRequestCICDFields[key]] = moment(value).toISOString();
      } else {
        finalResult[mapRequestCICDFields[key]] = value;
      }
    }
  });
  return finalResult;
};

export const sortArrayByTimetimestamp = (arr, field = "timestamp") => {
  return (
    arr?.sort((a, b) => {
      const timestampA = moment(a[field]);
      const timestampB = moment(b[field]);

      // Compare the timestamps in descending order
      return timestampA.diff(timestampB);
    }) || []
  );
};

export const specifyDurationBreak = ({
  startTime: startTimeParam,
  endTime: endTimeParam,
  pipelines,
}) => {
  let startTime = pipelines?.[0]?.timestamp || startTimeParam;
  let endTime = pipelines?.slice?.(-1)?.[0]?.timestamp || endTimeParam;

  if (startTime && endTime) {
    const resultInDays = moment(new Date(endTime)).diff(
      moment(new Date(startTime)),
      "days",
    );

    if (resultInDays > 31) {
      return {
        unit: "month",
        displayFormats: {
          month: "MM/YYYY",
        },
      };
    }

    if (resultInDays > 1) {
      return {
        unit: "day",
        displayFormats: {
          day: "DD/MM",
        },
      };
    }
  }

  return {
    unit: "hour",
    displayFormats: {
      hour: "HH:mm",
    },
  };
};

export const PIPELINE_ITEM_TOOLTIP_DEFAULT = {
  callbacks: {
    title: (tooltipItems) => {
      return `App Name: ${get(tooltipItems, "[0].raw.appName") || "N/A"}`;
    },
    label: function (context) {
      let label = context.dataset.label || "";
      return `${label} : ${context?.raw?.y || context?.formattedValue}m`;
    },
    footer: (tooltipItems) => {
      return `Executed Time: ${moment(
        get(tooltipItems, "[0].raw.timestamp"),
      ).format(DATETIME_FORMAT)}`;
    },
  },
  backgroundColor: "rgba(0, 0, 0, 0.6)",
};

export const genAgoTime = (minutes, currentDate) => {
  return new Date((currentDate || new Date()).getTime() - minutes * 60000);
};

const numberView = (value) => (value >= 10 ? value : `0${value}`);

export const formatDuration = (seconds) => {
  const duration = moment.duration(seconds, "seconds");

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const remainingSeconds = duration.seconds();

  const formattedDuration = `${
    hours ? `${numberView(hours)}h ` : ""
  } ${numberView(minutes)}m ${numberView(remainingSeconds)}s`;

  return formattedDuration.trim();
};

export const customFromNow = (date) => {
  const pastDate = moment(new Date(date));
  const duration = moment.duration(moment().diff(pastDate));

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let result = "";

  if (days > 0) result += days + "d ";
  if (hours > 0) result += hours + "h ";
  if (minutes > 0) result += numberView(minutes) + "m ";
  if (seconds > 0) result += numberView(seconds) + "s ";

  return result + "ago";
};

export const roundNumber = (number, digit = 2) => Number(number.toFixed(digit));

export const genHourOnlyFormatfunc = (date, type = "hour") => {
  let value = moment(new Date(date))
    .set("minutes", 0)
    .set("seconds", 0)
    .set("milliseconds", 0);

  if (type === "day") {
    value.set("hours", 0);
  } else if (type === "month") {
    value.set("hours", 0);
    value.set("date", 1);
  } else if (type === "week") {
    value = value.clone().startOf("isoWeek");
  } else if (type === "year") {
    return moment(value).format("YYYY");
  }

  return value.toDate();
};
export const dateDiffIn = (startTime, endTime, type = "days") => {
  return (
    Math.ceil(
      moment(new Date(endTime)).diff(
        moment(new Date(startTime)),
        type,
        type === "months",
      ),
    ) + 1
  );
};

export const transformDataToExportCommon = (data, options, fileName) => {
  const { transformExportData } = options || {};

  const isCustomizeData = typeof transformExportData === "function";
  let transformData;

  const omitFields = (finalLine) => {
    const newFinalLine = cloneDeep(finalLine);
    Object.keys(newFinalLine).forEach((key) => {
      if (!newFinalLine[key] && newFinalLine[key] !== 0) {
        newFinalLine[key] = "";
      }

      if (
        (typeof newFinalLine[key] === "object" &&
          !(newFinalLine[key] instanceof Date)) ||
        Array.isArray(newFinalLine[key]) ||
        newFinalLine[key] === "Duration" ||
        key === "x"
      ) {
        delete newFinalLine[key];
      }
    });
    return newFinalLine;
  };

  if (data.datasets?.length && !isEmpty(options)) {
    const datasets = data.datasets.filter(
      (i) => !i?.label?.includes("Trendline"),
    );

    // this is data from chart
    transformData = datasets.reduce((final, cur) => {
      const formatData = cur?.data?.map((lineParam, index) => {
        let line = isCustomizeData
          ? transformExportData(lineParam)
          : cloneDeep(lineParam);

        if (typeof line === "number") {
          line = cur?.pipelines?.[index];
          line.y = roundNumber(line.duration / CONVERT_TIME_UNIT, 2);
        }

        let finalLine = {};
        const exportFields = FIELD_EXPORT_CONFIG[fileName];

        if (exportFields) {
          for (const [key, value] of Object.entries(exportFields)) {
            finalLine[value] = line[key];
          }
        } else {
          finalLine = omit(
            {
              ...line,
              label: cur?.label || line?.label,
            },
            ["x", "y"],
          );

          const titleX = options?.scales?.x?.title?.text || "x";
          const titleY = options?.scales?.y?.title?.text || "y";

          finalLine[titleX] = line.x;
          finalLine[titleY] = line.y;
        }

        return omitFields(finalLine);
      });

      return [...final, ...formatData];
    }, []);
  } else if (data?.length) {
    // this is normal list data

    transformData = data?.map((line) => {
      const exportFields = FIELD_EXPORT_CONFIG[fileName];
      const finalData = isCustomizeData ? transformExportData(line) : line;

      if (!exportFields) return finalData;

      return Object.entries(exportFields).reduce((acc, [key, label]) => {
        return { ...acc, [label]: finalData[key] };
      }, {});
    });
  }

  return transformData;
};

// This function exports common data as MVP for the export feature.
// In the future, we can add more details to each chart.
export const exportCommonChartFunc = (data, options, formatData, fileName) => {
  try {
    const transformData = transformDataToExportCommon(
      data,
      options,
      formatData,
    );
    exportFile(transformData, fileName || formatData);
  } catch (error) {
    CommonUIControl.ShowWarningToast("Parsing data is failed!");
  }
};

export const showMetric = (customizeData, keys) => {
  const rootKey = keys?.map((key) => `['${key}']`).join(".");
  const finalkey = `${rootKey}.show`;

  return get(customizeData, finalkey, true);
};

export const showMetricV2 = (customizeData, keys) => {
  const rootKey = keys?.map((key) => `['${key}']`).join(".");

  return {
    show: get(customizeData, `${rootKey}.show`, true),
    span: `col-span-${get(customizeData, `${rootKey}.span`, 1)}`,
  };
};

export const getCustomizeDataStorage = ({ subkey }) => {
  let currentData = {};
  try {
    currentData = JSON.parse(
      localStorage.getItem("customized-metrics") || "{}",
    );
  } catch (_) {
    // eslint-disable-next-line no-empty
  }

  if (subkey) return currentData[subkey];

  return currentData;
};

export const setCustomizeDataStorage = ({ subkey, data }) => {
  const overalData = getCustomizeDataStorage({});
  overalData[subkey] = data;

  localStorage.setItem("customized-metrics", JSON.stringify(overalData));
};

// Function to adjust spans for items in a single row
const adjustRowSpans = (items, rowSpan) => {
  let totalSpan = items.reduce((sum, item) => sum + item.span, 0);
  let remainingSpan = rowSpan - totalSpan;

  if (remainingSpan > 0) {
    const additionalSpan = Math.floor(remainingSpan / items.length);
    const remainderSpan = remainingSpan % items.length;

    items.forEach((item, index) => {
      item.span += additionalSpan;
      if (index < remainderSpan) {
        item.span += 1;
      }
    });
  }
};

export const adjustSpans = (metrics, rowSpan = 4) => {
  // Process each category in the metrics object
  Object.entries(metrics).forEach(([category, items]) => {
    // Collect all visible items
    const visibleItems = Object.entries(items)
      .filter(([, value]) => value.show)
      .map(([key, value]) => ({ key, ...value }));

    // Adjust spans row by row
    let currentRowSpan = 0;
    let currentRowItems = [];
    visibleItems.forEach((item) => {
      currentRowSpan += item.span;
      currentRowItems.push(item);

      // If current row span exceeds rowSpan, adjust spans and reset for next row
      if (currentRowSpan >= rowSpan) {
        adjustRowSpans(currentRowItems, rowSpan);
        currentRowSpan = 0;
        currentRowItems = [];
      }
    });

    // Adjust spans for the last row if any items left
    if (currentRowItems.length > 0) {
      adjustRowSpans(currentRowItems, rowSpan);
    }

    // Update the metrics object with adjusted spans
    currentRowItems.forEach((item) => {
      metrics[category][item.key].span = item.span;
    });
  });

  return metrics;
};

export const generateTrendlinePipelines = (data, stackedChart) => {
  if (!data.datasets?.length) return;

  if (stackedChart) {
    const totals = {};
    data.datasets.forEach((item) => {
      item.data.forEach((entry) => {
        const dateKey = moment(entry.x).format("MM/DD/YYYY");
        if (!totals[dateKey]) {
          totals[dateKey] = 0;
        }
        totals[dateKey] += entry.y;
      });
    });

    const result = Object.entries(totals).map(([date, total]) => ({
      x: date,
      y: total,
    }));
    result.sort((a, b) => new Date(a.x) - new Date(b.x));

    if (result.length <= 5) return;
    const trendline = calculateTrendline(result);
    const trendlineData = [];
    for (let i = 0; i < result.length; i++) {
      trendlineData.push({
        x: result[i].x,
        y: trendline.slope * (i + 1) + trendline.intercept,
      });
    }
    const trendlineDataset = {
      label: "Trendline",
      type: "line",
      data: trendlineData,
      borderColor: COLORS.PRIMARY,
      fill: false,
      borderWidth: 1,
      borderDash: [8, 4],
      pointRadius: 0, // Hide the data points (circles)
      zIndex: 1000,
    };
    data.datasets.push(trendlineDataset);

    return;
  }

  for (let i = 0; i < data.datasets.length; i++) {
    const trendline = calculateTrendline(data.datasets[i].data);
    const trendlineData = [];
    const labels = data.datasets[i].data;
    // Do not draw trendline if dataset length <= 5
    if (labels.length <= 5) continue;
    for (let i = 0; i < labels.length; i++) {
      trendlineData.push({
        ...labels[i],
        x: labels[i].x,
        y: trendline.slope * (i + 1) + trendline.intercept,
      });
    }
    const trendlineDataset = {
      label: `Trendline(${data.datasets[i].label})`,
      type: "line",
      data: trendlineData,
      borderColor: data.datasets[i].borderColor || COLORS.PRIMARY,
      fill: false,
      borderWidth: 1,
      borderDash: [8, 4],
      pointRadius: 0, // Hide the data points (circles)
      zIndex: 1000,
    };
    data.datasets.splice(i + 1, 0, trendlineDataset); // Insert after the current item
    i++; // Increment i to avoid infinite loop
  }
};

function calculateTrendline(data) {
  const n = data.length;
  let sumX = 0,
    sumY = 0,
    sumXY = 0,
    sumX2 = 0;

  for (let i = 0; i < n; i++) {
    const x = i + 1; // Assuming x values are sequential (1, 2, 3, ...)
    const y = data[i].y || 0;
    sumX += x;
    sumY += y;
    sumXY += x * y;
    sumX2 += x * x;
  }

  const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  return { slope, intercept };
}

export function drawUslLine(value = 0) {
  return {
    type: "line",
    mode: "horizontal",
    scaleID: "y",
    value, // Adjust this value to set the y-coordinate of the horizontal line
    borderColor: COLORS.WARNING,
    borderWidth: 1,
    borderDash: [8, 4],
    label: {
      content: "Threshold: 70", // Your label content
      enabled: true,
      position: "left", // Adjust the position of the label
    },
  };
}
