
<div>
  <RDECatalogTable
    showExpand
    :headers="headers"
    :items="imageGroups"
    item-key="id"
    singleExpand
  >
    <template v-slot:[`item.icon`]="{ item }">
      <v-icon v-if="item.type === 'unknown'"> mdi-file-question </v-icon>
      <SvgIcon
        v-else
        :iconName="item.type === 'webssh' ? 'ssh' : item.type"
        :size="item.type === 'webssh' ? 'md' : 'sm'"
        :color="getIconColor(item.type)"
      />
    </template>
    <template #toolbar>
      <div v-if="isAdmin" class="flex justify-end mb-2">
        <Button
          fill
          color="primary"
          class="normal-case"
          size="md"
          @click="openPopup"
        >
          <v-icon class="mr-1">mdi-plus</v-icon>
          Add
        </Button>
      </div>
    </template>
    <template #expanded-item="{ item }">
      <RDEChildTable :items="item.images" :headers="imageHeaders">
        <template v-slot:[`item.action`]="{ item }">
          <div v-if="isAdmin" class="flex justify-end">
            <v-icon
              class="cursor-pointer"
              color="#E55353"
              @click="() => removeImage(item)"
            >
              mdi-delete
            </v-icon>
          </div>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          {{ item.created | formatDate }}
        </template>
      </RDEChildTable>
    </template>
    <template v-slot:[`item.created`]="{ item }">
      {{ item.created | formatDate }}
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <div v-if="isAdmin" class="flex justify-end gap-2">
        <v-icon class="cursor-pointer" @click="() => editImageGroup(item)">
          mdi-pencil
        </v-icon>
        <v-icon
          class="cursor-pointer"
          color="#E55353"
          @click="() => removeImageGroup(item)"
        >
          mdi-delete
        </v-icon>
      </div>
    </template>
  </RDECatalogTable>
  <Dialog
    :value="isOpenPopup"
    persistent
    :title="defaultEditValue ? 'Edit Group' : 'Create New Group'"
    max-width="800"
    min-height="700"
    @input="(value) => (!value ? closePopup() : (isOpenPopup = true))"
  >
    <ImageGroupPopup
      v-if="isOpenPopup"
      :closePopup="closePopup"
      :reloadData="fetchImageGroup"
      :defaultEditValue="defaultEditValue"
    />
  </Dialog>
</div>
