
<Form
  v-if="defaultValues"
  class="w-full"
  :defaultValues="defaultValues"
  v-bind="$attrs"
  :before="handleSubmitBefore"
  v-on="$listeners"
>
  <template #default="{ values }">
    <div class="grid grid-cols-2 gap-x-2">
      <Input
        v-model="values.name"
        label="*Enter package name"
        :rules="[rules.required, rules.name]"
        appendClass="app-g-input-v2"
        :disabled="!!defaultEditValue"
      />
      <Input
        v-model="values.displayName"
        label="*Display name"
        :rules="[rules.required]"
        appendClass="app-g-input-v2"
      />
      <v-textarea
        v-model="values.description"
        class="col-span-2 mt-1 app-g-text-area-v2"
        outlined
        label="Enter description of this package"
      ></v-textarea>

      <Checkbox
        v-model="values.type"
        label="ai-extension"
        true-value="ai-extension"
        false-value=""
      />
    </div>
    <div class="mt-3 mb-3 text-big-label">Selected Extensions</div>

    <RDECatalogTable
      :value="selectedExtensions"
      show-select
      :headers="headers"
      :items="sortedExtensions"
      item-key="customId"
      item-value="customId"
      :items-per-page="5"
      @input="selectItems"
    >
    </RDECatalogTable>

    <div class="flex justify-end gap-x-2 mt-4">
      <Button :size="'md'" @click="closePopup">Cancel</Button>
      <Button :type="'submit'" color="primary" :size="'md'" text fill>
        Save
      </Button>
    </div>
  </template>
</Form>
