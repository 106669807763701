<template>
  <div class="compositions__tabs__tab">
    <v-tab class="tabs__tab" v-bind="$attrs" v-on="$listeners">
      <template #default>
        <slot />
      </template>
    </v-tab>

    <!-- tooltip -->
    <div v-if="tooltip" class="tab__tooltip">
      <DescriptionTooltip :iconName="tooltipIconName">
        {{ tooltip }}
      </DescriptionTooltip>
    </div>
  </div>
</template>

<script>
import DescriptionTooltip from "../DescriptionTooltip/DescriptionTooltip.vue";

export default {
  props: {
    /** 문자열 정의 시 툴팁을 표시한다 */
    tooltip: {
      type: [String, Boolean],
      default: undefined,
    },
    /** 툴팁의 아이콘을 정의(`tooltip`속성 정의 필요) */
    tooltipIconName: {
      type: String,
    },
  },
  components: { DescriptionTooltip },
};
</script>

<style lang="scss">
.compositions__tabs__tab {
  .tabs__tab {
    @apply h-11 text-gray-600 font-semibold;

    /** 활성화 */
    &.v-tab--active {
      @apply bg-white relative;
      color: var(--color-primary) !important;
    }

    /** 비활성화 */
    &[disabled="disabled"] {
      @apply text-gray-200 opacity-100;
      color: #d1d5db !important;
    }
  }

  .tab__tooltip {
    height: 0px;
    transform: translate(85%, -44px);
  }
}
</style>
