import httpClient from "../httpClient";
import qs from "qs";
import CommonUIControl from "@/helper/CommonUIControl";

export default {
  // get dashboard 1 -  v1/dashboard/cicd-dashboard
  getCICDDashboard(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("dashboard/cicd-dashboard", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  // get dashboard 1 for 1 app detail
  getCICDAppDetailDashboard(appId, params = { builds: 10 }) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`dashboard/cicd-dashboard/${appId}`, {
          params,
          timeout: 15000,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  // get pods by application
  getPodsDashboard({ clusterId, namespace, name }) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/kubernetes-dashboard/pods", {
          params: { clusterId, namespace, name },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },

  // get dashboard 2 -  Kubernetes
  getKubernetesDashboard(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/kubernetes-dashboard", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },

  // get Groups by project
  getServiceTypesDashboard(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/filter/service-types", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  // get dashboard DORA -  v1/dashboard/dora-dashboard
  getDORADashboard(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("dashboard/devops-dashboard", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  getDevopsMetricConfig(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/devops-dashboard/devops-metric-config/get", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  saveDevopsMetricConfig(data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/dashboard/devops-dashboard/devops-metric-config/create-or-update`,
          data,
          {
            baseURL:
              process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
              "https://ags-dashboard.amdp-dev.skamdp.org",
          },
        )
        .then((resp) => {
          if (resp && resp.data) {
            resolve(resp.data);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },
  deleteDevopsMetricConfig(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/dashboard/devops-dashboard/devops-metric-config/delete`, {
          params,
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((resp) => {
          if (resp?.status === 200) {
            resolve(resp);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },
  syncManualDevopsMetricConfig(id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/dashboard/devops-dashboard/devops-metric-config/sync?id=${id}`, {
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((resp) => {
          if (resp && resp.status === 200) {
            resolve(resp);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },
  getJiraConfig(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/config/jira/get-config", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  getAllJiraConfig() {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/config/jira/get-all-config", {
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  saveJiraConfig(data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/dashboard/config/jira/create-or-update`,
          data,
          {
            baseURL:
              process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
              "https://ags-dashboard.amdp-dev.skamdp.org",
          },
        )
        .then((resp) => {
          if (resp && resp.data) {
            resolve(resp.data);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },
  deleteJiraConfig(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/dashboard/config/jira/delete`, {
          params,
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((resp) => {
          if (resp?.status === 200) {
            resolve(resp);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },
  syncManualJiraConfig(id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/dashboard/config/jira/sync?id=${id}`, {
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((resp) => {
          if (resp && resp.status === 200) {
            resolve(resp);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },
  getStepDetailCICD(body) {
    return new Promise((resolve, reject) => {
      httpClient
        .post("dashboard/cicd-dashboard/pipeline-info", body, {
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  getTopActiveApp(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/productivity-dashboard/recent-apps", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  getTopSignificantPR(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/productivity-dashboard/recent-prs", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  getRdeMetrics() {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/rde-dashboard/rde-list-metrics", {
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },

  requestDashboardData(url, params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(url, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
          timeout: 20000,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  getCommitFrequency(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/commit-frequency",
      params,
    );
  },
  getCodingTime(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/coding-time",
      params,
    );
  },
  getAvgReviewDuration(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/avg-review-duration",
      params,
    );
  },
  getAveragePrSize(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/average-pr-size",
      params,
    );
  },
  getHistoricalWorkOnBranches(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/branches-work-historical",
      params,
    );
  },
  getOngoingPRs(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/on-going-pr",
      params,
    );
  },
  getTop10ContributorsByPRs(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/top-pr-contributors",
      params,
    );
  },
  getTop10MostDeployedApplications(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/top-deployed-apps",
      params,
    );
  },
  getTop10MostCommittedApplications(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/top-most-commit-contributors",
      params,
    );
  },
  getTop10MostBuiltApplications(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/top-built-apps",
      params,
    );
  },
  getCodeQualityMetrics(params) {
    return this.requestDashboardData(
      "/dashboard/code-quality/all-metrics",
      params,
    );
  },
  getCodeQualityLoc(params) {
    return this.requestDashboardData("/dashboard/code-quality/loc", params);
  },
  getCodeQualityCodeChurn(params) {
    return this.requestDashboardData(
      "/dashboard/code-quality/code-churn",
      params,
    );
  },
  getUSLData(params) {
    return this.requestDashboardData(
      "/dashboard/devops-dashboard/devops-metric-config/get-usl",
      params,
    );
  },
  getTopContributor(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/top-contributors-over-time",
      params,
    );
  },
  getTopContributorOfWeek(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/top-contributors-by-week",
      params,
    );
  },
  getTopReviewer(params) {
    return this.requestDashboardData(
      "/dashboard/productivity-dashboard/top-reviewer",
      params,
    );
  },
  getSonarConfig(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/config/sonar/get-all-config", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  saveSonarConfig(data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/dashboard/config/sonar/create-or-update`, data, {
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((resp) => {
          if (resp && resp.data) {
            resolve(resp.data);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },

  getSonarConfigDetail(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/config/sonar/get-config", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  deleteSonarConfig(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/dashboard/config/sonar/delete`, {
          params,
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((resp) => {
          if (resp?.status === 200) {
            resolve(resp);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },
  syncManualSonarConfig(id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/dashboard/config/sonar/sync?id=${id}`, {
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((resp) => {
          if (resp && resp.status === 200) {
            resolve(resp);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },
};
