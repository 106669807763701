<template>
  <div>
    <RDECatalogTable
      showExpand
      :headers="headers"
      :items="imageGroups"
      item-key="id"
      singleExpand
    >
      <template v-slot:[`item.icon`]="{ item }">
        <v-icon v-if="item.type === 'unknown'"> mdi-file-question </v-icon>
        <SvgIcon
          v-else
          :iconName="item.type === 'webssh' ? 'ssh' : item.type"
          :size="item.type === 'webssh' ? 'md' : 'sm'"
          :color="getIconColor(item.type)"
        />
      </template>
      <template #toolbar>
        <div v-if="isAdmin" class="flex justify-end mb-2">
          <Button
            fill
            color="primary"
            class="normal-case"
            size="md"
            @click="openPopup"
          >
            <v-icon class="mr-1">mdi-plus</v-icon>
            Add
          </Button>
        </div>
      </template>
      <template #expanded-item="{ item }">
        <RDEChildTable :items="item.images" :headers="imageHeaders">
          <template v-slot:[`item.action`]="{ item }">
            <div v-if="isAdmin" class="flex justify-end">
              <v-icon
                class="cursor-pointer"
                color="#E55353"
                @click="() => removeImage(item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            {{ item.created | formatDate }}
          </template>
        </RDEChildTable>
      </template>
      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | formatDate }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div v-if="isAdmin" class="flex justify-end gap-2">
          <v-icon class="cursor-pointer" @click="() => editImageGroup(item)">
            mdi-pencil
          </v-icon>
          <v-icon
            class="cursor-pointer"
            color="#E55353"
            @click="() => removeImageGroup(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
    </RDECatalogTable>
    <Dialog
      :value="isOpenPopup"
      persistent
      :title="defaultEditValue ? 'Edit Group' : 'Create New Group'"
      max-width="800"
      min-height="700"
      @input="(value) => (!value ? closePopup() : (isOpenPopup = true))"
    >
      <ImageGroupPopup
        v-if="isOpenPopup"
        :closePopup="closePopup"
        :reloadData="fetchImageGroup"
        :defaultEditValue="defaultEditValue"
      />
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/components/compositions/Dialog/Dialog.vue";
import RDECatalogTable from "../../components/RDECatalogTable.vue";
import RDEChildTable from "../../components/RDEChildTable.vue";
import ImageGroupPopup from "./ImageGroupPopup.vue";
import moment from "moment";
import CommonUIControl from "@/helper/CommonUIControl";
import { requestImageGroups } from "@/service/apis/workspaceApis";
import { mapState } from "vuex";

export default {
  components: { RDECatalogTable, RDEChildTable, Dialog, ImageGroupPopup },
  data: () => ({
    headers: [
      { value: "icon", sortable: false, width: "5%", minWidth: 40 },
      {
        text: "Type",
        value: "type",
        width: "10%",
        minWidth: 100,
      },
      {
        text: "Group Name",
        value: "name",
        width: "20%",
        minWidth: 150,
      },
      {
        text: "Description",
        value: "description",
        width: "30%",
        minWidth: 200,
        sortable: false,
      },
      {
        text: "Created",
        value: "created",
        width: "20%",
        sortable: false,
        minWidth: 100,
      },
      {
        text: "Action",
        value: "action",
        width: "5%",
        sortable: false,
        minWidth: 100,
      },
    ],
    imageHeaders: [
      { text: "", value: "emptyCol", width: 100, sortable: false },
      { text: "Image Name", value: "imageName", width: "15%" },
      { text: "Image Name Detail", value: "imageNameDetail", width: "15%" },
      { text: "Description", value: "description", width: "20%" },
      {
        text: "Type",
        value: "type",
        width: "15%",
      },
      { text: "Version", value: "version", width: "15%" },
      { text: "Created", value: "created", width: "15%" },
      { text: "Action", value: "action", width: 70 },
    ],
    imageGroups: [],
    isOpenPopup: false,
    defaultEditValue: null,
  }),
  computed: {
    ...mapState({
      isAdmin: (state) => {
        return ["ADMINISTRATOR", "ADMIN"].includes(
          state.accountInfo?.auth?.toUpperCase() || "",
        );
      },
    }),
  },
  methods: {
    openPopup: function () {
      this.isOpenPopup = true;
    },
    removeImage(item) {
      CommonUIControl.ShowModalDialog(
        "Confirm",
        `Are you sure to remove '${item.imageName}' from group?`,
        "Yes",
        "No",
        async (flag) => {
          if (flag) {
            const selectedImageGroup = this.imageGroups.find(
              (g) => g.id == item.groupId,
            );

            if (selectedImageGroup) {
              selectedImageGroup.images = selectedImageGroup.images.filter(
                (image) =>
                  !(
                    image.imageName === item.imageName &&
                    image.version === item.version
                  ),
              );
            }
            CommonUIControl.ShowUIProgress();
            try {
              await requestImageGroups({
                method: "put",
                data: selectedImageGroup,
                path: `/${item.groupId}`,
              });
              await this.fetchImageGroup(false);
            } catch (error) {
              CommonUIControl.ShowErrorToast(
                error?.message || "Remove image failed",
                5000,
              );
            } finally {
              CommonUIControl.HideUIProgress();
            }
          }
        },
        true,
        "question",
      );
    },
    removeImageGroup(group) {
      CommonUIControl.ShowModalDialog(
        "Confirm",
        `Are you sure to delete group '${group.name}'?`,
        "Yes",
        "No",
        async (flag) => {
          if (flag) {
            CommonUIControl.ShowUIProgress();

            try {
              await requestImageGroups({
                path: `/${group.id}`,
                method: "delete",
              });
              await this.fetchImageGroup(false);
            } catch (error) {
              CommonUIControl.ShowErrorToast(
                error?.message || "Delete Group failed",
                5000,
              );
            } finally {
              CommonUIControl.HideUIProgress();
            }
          }
        },
        true,
        "question",
      );
    },
    editImageGroup(item) {
      this.isOpenPopup = true;
      this.defaultEditValue = item;
    },
    closePopup() {
      this.isOpenPopup = false;
      this.defaultEditValue = null;
    },
    async fetchImageGroup(showLoading) {
      if (showLoading) CommonUIControl.ShowUIProgress();
      requestImageGroups()
        .then((res) => {
          this.imageGroups = (res?.data || []).map((group) => ({
            ...group,
            images: (group?.images || []).map((image) => ({
              ...image,
              groupId: group.id,
            })),
          }));
        })
        .catch(() => {
          if (showLoading)
            CommonUIControl.ShowErrorToast("Fetch Image Group Failed", 5000);
        })
        .finally(() => {
          CommonUIControl.HideUIProgress();
        });
    },
    getIconColor(type) {
      const colorMap = {
        jupyter: "#F78C6C",
        vscode: "#4FC3F7",
        webssh: null,
        unknown: null,
      };
      return colorMap[type];
    },
  },
  filters: {
    formatDate(value) {
      return value ? moment(new Date(value)).format("YYYY/MM/DD HH:mm:ss") : "";
    },
  },
  mounted() {
    this.fetchImageGroup(true);
  },
};
</script>

<style lang="scss"></style>
