
<v-autocomplete
  ref="multiSelect"
  v-model="internalValue"
  :label="label"
  :items="localItems"
  small-chips
  :placeholder="placeholder"
  :class="extClass"
  :clearable="true"
  :deletable-chips="true"
  multiple
  outlined
  dense
  @change="onClearCheckBox"
>
  <template v-slot:selection="{ index }">
    <v-chip v-if="selectedChild" :key="index" small class="my-chip">
      <div class="text-center flex flex-col">
        <span class="name">
          {{ selectedChild.imageName }}.{{ selectedChild?.imageNameDetail }}:
          {{ selectedChild?.version || "" }}</span
        >
      </div>
    </v-chip>
  </template>

  <template v-slot:item="{ item, index }">
    <v-list-item :key="index" class="image-list" @click.stop>
      <v-list-item-content>
        <v-radio-group
          v-model="selectedValue"
          hide-details
          class="rdo-group w-full"
        >
          <div class="w-full flex flex-row gap-2 parent-item">
            <!-- <div>
              <v-radio
                class="pt-5"
                :value="item"
                @change="toggleParent(item)"
              ></v-radio>
            </div> -->
            <v-list-group v-model="item.expanded" class="w-full" @click.stop>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="font-bold">
                      {{ item.name }}
                    </div>
                    <div class="extra-info">
                      Type: {{ item.type }} <br />
                      Description:
                      {{
                        $helper.truncatedText(
                          item?.description?.toString() || "",
                          30,
                        )
                      }}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(child, childIndex) in item.images"
                :key="childIndex"
                @click.stop
              >
                <v-list-item-content
                  class="w-full flex flex-row gap-2 child-item"
                >
                  <div class="w-fit flex-none">
                    <v-radio
                      :value="child"
                      @change="toggleChild(item, child)"
                    ></v-radio>
                  </div>

                  <div class="child-body w-fit flex-none py-2">
                    <div class="font-bold py-1">
                      {{ child.imageNameDetail }}
                    </div>
                    <div class="extra-info">
                      Image Name: {{ child.imageName }} <br />
                      version: {{ child.version }}
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </v-radio-group>
      </v-list-item-content>
    </v-list-item>
  </template>
</v-autocomplete>
