
<div class="compositions__tabs__tab">
  <v-tab class="tabs__tab" v-bind="$attrs" v-on="$listeners">
    <template #default>
      <slot />
    </template>
  </v-tab>

  <!-- tooltip -->
  <div v-if="tooltip" class="tab__tooltip">
    <DescriptionTooltip :iconName="tooltipIconName">
      {{ tooltip }}
    </DescriptionTooltip>
  </div>
</div>
