<template>
  <div>
    <RDECatalogTable
      showExpand
      :headers="headers"
      :items="extensions"
      item-key="name"
      singleExpand
      ignoreTdSpanDetail
      :showExpandByRow="showExpandByRow"
    >
      <!-- <template #toolbar>
        <div class="flex justify-end mb-2">
          <Button
            fill
            color="primary"
            class="normal-case"
            size="md"
            @click="openPopup"
          >
            <v-icon class="mr-1">mdi-plus</v-icon>
            Add
          </Button>
        </div>
      </template> -->
      <template #expanded-item="{ item }">
        <tr
          v-for="childExtension in item.versions"
          :key="childExtension.version"
        >
          <td></td>
          <td></td>
          <td></td>
          <td>
            <span class="line-clamp-2 break-all">
              {{ childExtension.version }}
            </span>
          </td>
          <td>
            <span class="line-clamp-2 break-all">
              {{ childExtension.size }}
            </span>
          </td>
          <td>
            <span class="line-clamp-2 break-all">
              {{ childExtension.created | formatDate }}
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | formatDate }}
      </template>
    </RDECatalogTable>
    <Dialog
      :value="isOpenPopup"
      persistent
      title="Add Extension"
      max-width="800"
      min-height="700"
      @input="() => (isOpenPopup = true)"
    >
      <ExtensionPopup
        v-if="isOpenPopup"
        :closePopup="closePopup"
        :reloadData="fetchExtensions"
      />
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/components/compositions/Dialog/Dialog.vue";
import RDECatalogTable from "../../components/RDECatalogTable.vue";
import moment from "moment";
import CommonUIControl from "@/helper/CommonUIControl";
import { groupBy, orderBy } from "lodash";
import ExtensionPopup from "./ExtensionPopup.vue";
import { requestExtensions } from "@/service/apis/workspaceApis";
// import { requestExtension } from "@/service/apis/workspaceApis";

export default {
  components: { RDECatalogTable, Dialog, ExtensionPopup },
  data: () => ({
    headers: [
      {
        text: "Organization",
        value: "organization",
        width: "20%",
        minWidth: 150,
      },
      {
        text: "Name",
        value: "name",
        width: "20%",
        minWidth: 150,
      },
      {
        text: "Version",
        value: "version",
        width: "20%",
        minWidth: 150,
      },
      { text: "Size", value: "size", width: "15%", minWidth: 150 },
      {
        text: "Created",
        value: "created",
        width: "20%",
        minWidth: 150,
        sortable: false,
      },
    ],
    extensions: [],
    isOpenPopup: false,
  }),
  methods: {
    openPopup: function () {
      this.isOpenPopup = true;
    },
    closePopup() {
      this.isOpenPopup = false;
    },
    showExpandByRow(item) {
      return !!item.versions.length;
    },
    async fetchExtensions(showLoading) {
      if (showLoading) CommonUIControl.ShowUIProgress();
      requestExtensions()
        .then((res) => {
          const extensions = res?.data || [];

          this.extensions = Object.values(
            groupBy(extensions, (e) => `${e.organization}-${e.name}`),
          ).map((extension) => {
            const sortByVersions = orderBy(extension, "version", "desc");
            const finalData = { ...sortByVersions[0] };
            const versions = sortByVersions;
            versions.shift();
            finalData.versions = versions;

            return finalData;
          });
        })
        .catch(() => {
          CommonUIControl.ShowErrorToast("Fetch extensions Failed", 5000);
        })
        .finally(() => {
          if (showLoading) CommonUIControl.HideUIProgress();
        });
    },
  },
  filters: {
    formatDate(value) {
      return value ? moment(new Date(value)).format("YYYY/MM/DD HH:mm:ss") : "";
    },
  },
  mounted() {
    this.fetchExtensions(true);
  },
};
</script>

<style lang="scss"></style>
