
<v-dialog
  class="compositions__dialog"
  :value="value"
  :maxWidth="maxWidth"
  :width="width"
  v-bind="$attrs"
  v-on="$listeners"
  @input="handleInput"
>
  <!-- header -->
  <div
    class="flex justify-between items-center h-12 border-b border-gray-300 px-4"
  >
    <h2 class="font-semibold text-gray-600">
      {{ title }}
    </h2>
    <div>
      <IconButton
        iconName="mdi-close"
        shape="circle"
        tooltip="닫기"
        :outlined="false"
        @click="$emit('input', false)"
      />
    </div>
  </div>

  <!-- content -->
  <div :class="[usePadding && 'p-4']">
    <slot></slot>
  </div>
</v-dialog>
