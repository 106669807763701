<template>
  <PageTemplate
    :useCard="false"
    :breadcrumbs="[
      {
        text: 'RDE Catalog',
      },
    ]"
    padding="p-3"
  >
    <div class="bg-white rounded-md">
      <Tabs v-model="tabIndex">
        <template #tabs>
          <Tab>IMAGE</Tab>
          <Tab>IMAGE GROUP</Tab>
          <Tab>EXTENSION PACKAGE</Tab>
          <Tab>EXTENSIONS</Tab>
        </template>
        <template #items>
          <TabItem><ImageTab /></TabItem>
          <TabItem><ImageGroupTab /></TabItem>
          <TabItem> <ExtensionPackageTab /> </TabItem>
          <TabItem> <ExtensionsTab /> </TabItem>
        </template>
      </Tabs>
    </div>
  </PageTemplate>
</template>

<script>
import ExtensionPackageTab from "./subtabs/ExtensionPackage/ExtensionPackageTab.vue";
import ExtensionsTab from "./subtabs/Extensions/ExtensionsTab.vue";
import ImageTab from "./subtabs/Image/ImageTab.vue";
import ImageGroupTab from "./subtabs/ImageGroup/ImageGroupTab.vue";

export default {
  components: { ImageTab, ImageGroupTab, ExtensionPackageTab, ExtensionsTab },
  data: () => ({ tabIndex: 0 }),
};
</script>

<style lang="scss"></style>
