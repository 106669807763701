<template>
  <div>
    <FileRDEUploader
      accept="vsix"
      multiple
      placeholder="Support for a single or bulk upload. Maximum (only .vsix) file size 2 GB."
      :size="2048"
      @input="uploadFile"
    />
    <div class="line-with-text">or</div>
    <div
      class="grid items-center gap-2"
      style="grid-template-columns: 1fr 70px"
    >
      <Input
        v-model="addLink"
        label="Import from URL"
        appendClass="app-g-input-v2"
      />
      <v-btn class="normal-case mb-1" elevation="0" medium fill block>
        <v-icon>mdi-plus</v-icon> Add
      </v-btn>
    </div>

    <div
      class="grid grid-cols-2 gap-x-2 pt-2 pb-3"
      style="color: #131316; font-weight: 500; font-size: 14px"
    >
      Selected Extensions
    </div>

    <RDECatalogTable
      hideSearch
      :headers="headers"
      :items="files"
      item-key="name"
      :items-per-page="5"
    >
      <template v-slot:[`item.action`]="{ item }">
        <div class="flex justify-end">
          <v-icon
            class="cursor-pointer"
            color="#E55353"
            @click="() => removeExtension(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
    </RDECatalogTable>

    <!-- <div class="grid" style="grid-template-columns: 1fr 50px">
      <Input
        v-for="(link, index) in links"
        :key="`link-${index}`"
        :value="link"
        label="Normal"
        appendClass="app-g-input-v2"
        readonly
      />
      <v-icon class="cursor-pointer" color="#E55353"> mdi-delete </v-icon>
    </div> -->

    <div class="flex justify-end gap-x-2 mt-4">
      <Button :size="'md'" @click="closePopup">Cancel</Button>
      <Button :type="'submit'" color="primary" :size="'md'" text fill>
        Save
      </Button>
    </div>
  </div>
</template>

<script>
import Input from "@/components/atoms/Input/Input.vue";
import CommonUIControl from "@/helper/CommonUIControl";
import { RulesMixin } from "@/mixins/RulesMixin";
import FileRDEUploader from "../../components/FileRDEUploader.vue";
import RDECatalogTable from "../../components/RDECatalogTable.vue";

export default {
  props: ["closePopup", "reloadData"],
  components: { Input, FileRDEUploader, RDECatalogTable },
  mixins: [RulesMixin],
  data: () => ({
    links: ["1.htttps://drive.google.com/somewhere"],
    files: [],
    selectedExtensions: [],
    headers: [
      { text: "Name", value: "name", width: "25%" },
      { text: "Organization", value: "organization", width: "25%" },
      { text: "Version", value: "version", width: "25%" },
      {
        text: "Size",
        value: "size",
        width: "20%",
      },
      { text: "Action", value: "action", width: 70 },
    ],
  }),
  methods: {
    // eslint-disable-next-line no-unused-vars
    uploadFile(...remains) {
      // todo
    },
    // eslint-disable-next-line no-unused-vars
    async handleSubmitBefore(values) {
      if (!this.selectedExtensions?.length) {
        CommonUIControl.ShowWarningToast(
          "You must select at least one extension.",
          5000,
        );
        return false;
      }

      CommonUIControl.ShowUIProgress();

      // const payload = {
      //   ...values,
      //   extensions: this.selectedExtensions,
      // };
      CommonUIControl.ShowUIProgress();

      // await requestImageGroups({
      //   method: this.defaultEditValue ? "put" : "post",
      //   data: payload,
      //   path: this.defaultEditValue ? `/${this.defaultEditValue.id}` : "",
      // })
      //   .catch((e) => {
      //     CommonUIControl.ShowErrorToast(e.response.data.message, 5000);
      //   })
      //   .finally(() => {
      //     CommonUIControl.HideUIProgress();
      //   });
      // setTimeout(() => {
      //   this.closePopup();
      //   this.reloadData();
      // }, 100);

      return false;
    },
  },
  mounted() {
    // TODO
    // Call get list extension packages
  },
};
</script>

<style lang="scss" scoped>
.line-with-text {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  color: #cccccc;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 6px;
}

.line-with-text::before,
.line-with-text::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #cccccc;
}
</style>
