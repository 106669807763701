<template>
  <div class="compositions__tabs" :class="[usePadding ? 'use-padding' : '']">
    <div :class="tabsClass">
      <v-tabs
        v-model="index"
        v-bind="$attrs"
        v-on="$listeners"
        @change="handleInputChange"
      >
        <template #default>
          <slot name="tabs" />
        </template>
      </v-tabs>
    </div>

    <hr />

    <div class="overflow-hidden" :class="itemsClass">
      <v-tabs-items v-model="index">
        <slot name="items" />
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    /** 변경시 지정한 탭을 활성화. */
    tabIndex: {
      type: Number,
      default: undefined,
    },
    /** tabs 컨테이너의 클래스 */
    tabsClass: {
      type: String,
      default: undefined,
    },
    /** items 컨테이너의 클래스 */
    itemsClass: {
      type: String,
      default: undefined,
    },
    /** true일 경우 기본 패딩 적용 */
    usePadding: {
      type: Boolean,
      default: true,
    },
    /** 정의시 query string 에 의존해 탭을 변경한다 */
    tabQuery: {
      type: String,
    },
  },
  emits: ["input", "change"],
  data: () => ({
    index: null,
  }),
  watch: {
    tabIndex: {
      immediate: true,
      handler(tabIndex) {
        this.index = tabIndex;
      },
    },
    value: {
      immediate: true,
      handler(value) {
        this.index = value;
      },
    },
    $route: {
      immediate: true,
      handler(route) {
        const currentQuery = route.query[this.tabQuery];
        if (!currentQuery) {
          return;
        }
        this.index = Number(currentQuery);
      },
    },
  },
  methods: {
    /**
     * value 변경
     * @param {number} i
     */
    handleInputChange(i) {
      this.$emit("change", i);

      if (this.tabQuery) {
        this.$router.push({
          query: {
            ...this.$route.query,
            [this.tabQuery]: i,
          },
        });
        return;
      }

      this.$emit("input", i);
    },
  },
};
</script>

<style lang="scss">
.compositions__tabs {
  /** tabs */
  .v-tabs-bar {
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border: 1px solid transparent !important;
  }
  .v-tabs-bar--is-mobile .v-slide-group__prev {
    display: none !important;
  }

  /** items */
  .v-tabs-items {
    box-shadow: none !important;
  }
  .v-tabs-items > .v-window__container {
    border-top: none !important;
    padding: initial !important;
  }

  /** controller */
  .v-slide-group__prev,
  .v-slide-group__next {
    background-color: transparent !important;
  }

  /** 기본 패딩 적용 */
  &.use-padding {
    .v-tabs-bar:not(.v-slide-group--has-affixes) {
      padding: 0px 20px 0px 20px !important;
    }

    .v-tabs-items > .v-window__container {
      padding: 20px !important;
    }
  }
}
</style>
