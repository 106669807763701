
<div class="rde-catalog-table">
  <div class="flex justify-between mb-1">
    <div class="flex items-center gap-2">
      <Input
        v-if="!hideSearch"
        v-model="searchText"
        label="Search"
        hide-details
        append-icon="mdi-magnify"
        outlined
        appendClass="app-g-input-v2 max-w-80"
        placeholder="Please enter your search"
      />
      <slot name="filter-extra"></slot>
    </div>

    <div>
      <slot name="toolbar"></slot>
    </div>
  </div>
  <v-data-table
    :headers="finalHeaders"
    :items="viewItems"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    :item-key="itemKey"
    :item-value="itemValue"
    :show-expand="showExpand"
    dense
    class="table-wrapper"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td v-if="!ignoreTdSpanDetail" :colspan="headers.length">
        <slot name="expanded-item" :item="item"></slot>
      </td>
      <slot v-else name="expanded-item" :item="item"></slot>
    </template>
    <template
      v-slot:item="{
        item,
        headers,
        index,
        isExpanded,
        expand,
        isSelected,
        select,
      }"
    >
      <tr>
        <td v-for="header in headers" :key="header.value">
          <div
            v-if="header.value === 'actions'"
            class="flex flex-row justify-center gap-1"
          ></div>

          <v-icon
            v-else-if="
              header.value === 'data-table-expand' && showExpandByRow(item)
            "
            @click="expand(!isExpanded)"
          >
            {{ isExpanded ? "mdi-chevron-down" : "mdi-chevron-right" }}
          </v-icon>

          <Checkbox
            v-else-if="header.value === 'data-table-select'"
            :value="isSelected"
            color="primary"
            class="pr-2"
            @click.stop="select(!isSelected)"
          />

          <!-- Check if there is a custom slot for this column -->
          <slot
            v-else
            :name="`item.${header.value}`"
            :item="item"
            :header="header"
            :index="index"
          >
            <!-- Default content if no custom slot is provided -->
            <span class="line-clamp-2 break-all">
              {{ item[header.value] }}
            </span>
          </slot>
        </td>
      </tr>
    </template>
  </v-data-table>
</div>
