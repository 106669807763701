
<div>
  <FileRDEUploader
    accept="vsix"
    multiple
    placeholder="Support for a single or bulk upload. Maximum (only .vsix) file size 2 GB."
    :size="2048"
    @input="uploadFile"
  />
  <div class="line-with-text">or</div>
  <div
    class="grid items-center gap-2"
    style="grid-template-columns: 1fr 70px"
  >
    <Input
      v-model="addLink"
      label="Import from URL"
      appendClass="app-g-input-v2"
    />
    <v-btn class="normal-case mb-1" elevation="0" medium fill block>
      <v-icon>mdi-plus</v-icon> Add
    </v-btn>
  </div>

  <div
    class="grid grid-cols-2 gap-x-2 pt-2 pb-3"
    style="color: #131316; font-weight: 500; font-size: 14px"
  >
    Selected Extensions
  </div>

  <RDECatalogTable
    hideSearch
    :headers="headers"
    :items="files"
    item-key="name"
    :items-per-page="5"
  >
    <template v-slot:[`item.action`]="{ item }">
      <div class="flex justify-end">
        <v-icon
          class="cursor-pointer"
          color="#E55353"
          @click="() => removeExtension(item)"
        >
          mdi-delete
        </v-icon>
      </div>
    </template>
  </RDECatalogTable>

  <!-- <div class="grid" style="grid-template-columns: 1fr 50px">
    <Input
      v-for="(link, index) in links"
      :key="`link-${index}`"
      :value="link"
      label="Normal"
      appendClass="app-g-input-v2"
      readonly
    />
    <v-icon class="cursor-pointer" color="#E55353"> mdi-delete </v-icon>
  </div> -->

  <div class="flex justify-end gap-x-2 mt-4">
    <Button :size="'md'" @click="closePopup">Cancel</Button>
    <Button :type="'submit'" color="primary" :size="'md'" text fill>
      Save
    </Button>
  </div>
</div>
