
<div class="rde-child-table">
  <v-data-table
    :headers="headers"
    :items="viewItems"
    :item-key="itemKey"
    class="table-child-wrapper"
    hide-default-footer
    :items-per-page="-1"
    disable-sort
    dense
  >
    <template v-slot:item="{ item, headers, index }">
      <tr>
        <td v-for="header in headers" :key="header.value">
          <slot
            :name="`item.${header.value}`"
            :item="item"
            :header="header"
            :index="index"
          >
            <!-- Default content if no custom slot is provided -->
            <span class="line-clamp-2 break-all">
              {{ item[header.value] }}
            </span>
          </slot>
        </td>
      </tr>
    </template>
  </v-data-table>
</div>
