
<PageTemplate
  :useCard="false"
  :breadcrumbs="[
    {
      text: 'RDE Catalog',
    },
  ]"
  padding="p-3"
>
  <div class="bg-white rounded-md">
    <Tabs v-model="tabIndex">
      <template #tabs>
        <Tab>IMAGE</Tab>
        <Tab>IMAGE GROUP</Tab>
        <Tab>EXTENSION PACKAGE</Tab>
        <Tab>EXTENSIONS</Tab>
      </template>
      <template #items>
        <TabItem><ImageTab /></TabItem>
        <TabItem><ImageGroupTab /></TabItem>
        <TabItem> <ExtensionPackageTab /> </TabItem>
        <TabItem> <ExtensionsTab /> </TabItem>
      </template>
    </Tabs>
  </div>
</PageTemplate>
