<template>
  <div class="rde-child-table">
    <v-data-table
      :headers="headers"
      :items="viewItems"
      :item-key="itemKey"
      class="table-child-wrapper"
      hide-default-footer
      :items-per-page="-1"
      disable-sort
      dense
    >
      <template v-slot:item="{ item, headers, index }">
        <tr>
          <td v-for="header in headers" :key="header.value">
            <slot
              :name="`item.${header.value}`"
              :item="item"
              :header="header"
              :index="index"
            >
              <!-- Default content if no custom slot is provided -->
              <span class="line-clamp-2 break-all">
                {{ item[header.value] }}
              </span>
            </slot>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    itemKey: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    searchText: {
      type: String,
      default: "",
    },
  },
  computed: {
    viewItems() {
      if (this.searchText) {
        return this.items.filter((item) => {
          return Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(this.searchText.toLowerCase()),
          );
        });
      }
      return this.items;
    },
  },
};
</script>

<style type="scss">
.rde-child-table {
  .table-child-wrapper {
    border: none !important;
    border-bottom: 1px solid var(--gray8) !important;
    border-radius: 0 !important;

    color: #00000099;
    font-size: 12px;

    tbody tr {
      background-color: var(--text-white) #ffffff; /* White for even rows */
      td {
        color: #343c6a;
        font-weight: 400;
        padding: 0px 10px !important;
      }
      height: 34px;
    }
    thead {
      border-radius: 0px !important;
      background-color: var(--gray8) !important;

      tr > th {
        vertical-align: middle;
        background-color: var(--gray8) !important;
        height: 41px !important;
      }

      tr > th > span,
      td > th > span {
        font-weight: 500 !important;
        font-size: 14px !important;
        color: var(--text-primary);
      }

      tr {
        height: 38px !important;
      }
      th {
        height: 38px !important;
        /* border-right-color: white; */
      }
      th:first-child {
        border-radius: 0px !important;
      }
      th:last-child {
        border-radius: 0px !important;
        border-right-color: var(--background-tetiary) !important;
      }
    }

    .v-data-table__wrapper {
      min-height: 70px;
      border-radius: 0px !important;
    }
  }
}
</style>
