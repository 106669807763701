<template>
  <div class="flex justify-center items-center h-full no-content">
    <span class="text-xs msg">{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: { type: String, default: "No data available." },
  },
};
</script>

<style lang="scss" scoped>
.no-content {
  .msg {
    color: #000 !important;
  }
}
</style>
