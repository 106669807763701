<template>
  <RDECatalogTable :headers="headers" :items="images">
    <template #filter-extra>
      <div class="font-normal text-xs">Show Selected Type</div>
      <v-btn-toggle
        v-model="typeFilters"
        group
        multiple
        active-class="btn-toggle-on"
        light
        color="white"
      >
        <v-btn
          v-for="type in types"
          :key="type"
          :value="type"
          :title="type"
          outlined
          class="btn-toggle-off"
        >
          <SvgIcon
            :iconName="type === 'webssh' ? 'ssh' : type"
            :size="type === 'webssh' ? 'md' : 'sm'"
            :color="typeFilters.includes(type) ? getIconColor(type) : '#434343'"
          />
        </v-btn>
      </v-btn-toggle>
    </template>

    <template v-slot:[`item.icon`]="{ item }">
      <v-icon v-if="item.type === 'unknown'"> mdi-file-question </v-icon>
      <SvgIcon
        v-else
        :iconName="item.type === 'webssh' ? 'ssh' : item.type"
        :size="item.type === 'webssh' ? 'md' : 'sm'"
        :color="getIconColor(item.type)"
      />
    </template>
    <template v-slot:[`item.created`]="{ item }">
      {{ item.created | formatDate }}
    </template>
  </RDECatalogTable>
</template>

<script>
import moment from "moment";
import RDECatalogTable from "../../components/RDECatalogTable.vue";
import CommonUIControl from "@/helper/CommonUIControl";
import { requestImages } from "@/service/apis/workspaceApis";
import { debounce } from "lodash";

export default {
  components: { RDECatalogTable },
  data: () => ({
    headers: [
      { value: "icon", sortable: false, width: "5%", minWidth: 40 },
      {
        text: "Type",
        value: "type",
        width: "7%",
        minWidth: 100,
      },
      { text: "Image Name", value: "imageName", width: "15%", minWidth: 150 },
      {
        text: "Image Name Detail",
        value: "imageNameDetail",
        width: "18%",
        minWidth: 200,
      },
      {
        text: "Version",
        value: "version",
        sortable: false,
        width: "20%",
        minWidth: 100,
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        width: "20%",
        minWidth: 200,
      },
      {
        text: "Created",
        value: "created",
        sortable: false,
        width: "15%",
        minWidth: 100,
      },
    ],
    typeFilters: [],
    types: ["vscode", "jupyter", "webssh"],
    images: [],
  }),
  filters: {
    formatDate(value) {
      return value ? moment(new Date(value)).format("YYYY/MM/DD HH:mm:ss") : "";
    },
  },
  watch: {
    typeFilters: {
      handler() {
        this.fetchImages();
      },
      immediate: true,
    },
  },
  methods: {
    fetchImages: debounce(function () {
      CommonUIControl.ShowUIProgress();
      let path = "";

      if (this.typeFilters.length > 0) {
        path = `?types=${this.typeFilters.join(",")}`;
      }
      requestImages({ path })
        .then((res) => {
          this.images = res?.data || [];
        })
        .catch(() => {
          CommonUIControl.ShowErrorToast("Fetch Image faield");
        })
        .finally(() => {
          CommonUIControl.HideUIProgress();
        });
    }, 200),
    getIconColor(type) {
      const colorMap = {
        jupyter: "#F78C6C",
        vscode: "#4FC3F7",
        webssh: null,
        unknown: null,
      };
      return colorMap[type];
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-toggle-off {
  border: 1px solid #d7d7d733 !important;
  background-color: #f8f9fa50 !important;
  border-radius: 8px !important;
}
.btn-toggle-on {
  border: 1px solid #321fdb !important;
  border-radius: 8px !important;
}
</style>
